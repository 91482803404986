.ReviewWrap::before {
  position: absolute;
  width: 100%;
  content: "";
  height: 50px;
  top: -50px;
  background: white;
  left: 0;
  display: none;
}
@screen lg {
  .ReviewWrap::before {
    @apply block;
  }
}
@screen 2xl {
  .ReviewWrap::before {
    width: calc(100% + 69px);
  }
}
